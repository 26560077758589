import React from "react"
import { Modal, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

function StaffAndBoardModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Row style={{width: '100%'}}>
                    <Col sm={3}>
                    <Img fluid={props.imgFluid} alt={props.staffName} className="rounded-circle d-block m-auto" style={{width: '150px', height: '150px'}} />
                    </Col>
                    <Col sm={9}>
                        <div>
                            <h3>{props.staffName}{' '}{props.lastName}</h3>
                            <h4>
                                {props?.info && (<span>{props.info}</span>)}
                            </h4>
                        </div>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
            {props?.bio && (<p>{props.bio}</p>)}
            </Modal.Body>
      </Modal>
    )
}

export default StaffAndBoardModal